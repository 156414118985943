export const USERS = 'admin/users';
export const ORDER_LIST_ENTITY = 'orders';
export const ASSETS_ENTITY = '/assets';
export const LOCATION_TYPES_ENTITY = '/location-types';
export const VARIANTS_ENTITY = '/variants';
export const AVAILABLE_DELIVERY_PARTNERS = '/delivery-partners/available';
export const FAQ = 'faq';
export const QUESTION_ENTITY = 'questions';
export const PRIVACY_POLICY_ENTITY = 'privacy-policy';
export const TERMS_AND_CONDITION_ENTITY = 'terms-conditions';
export const RESET_PASSWORD = 'auth/reset-password';
export const VERIFY_EMAIL = 'auth/verify-email';
export const FORGOT_PASSWORD_EMAIL_SEND = 'admin/email/send';
export const RESET_FORGOT_PASSWORD = 'admin/reset-password';
export const VERIFY_ADMIN_EMAIL = 'admin/verify-email';
export const UPDATE_PASSWORD = 'admin/update-password';
export const UPDATE_USER_PASSWORD = 'admin/update-user-password';
export const ADVERTISEMENT = 'Ads';
export const COUNTRY_LIST = 'Ads/country';
