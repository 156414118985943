/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { QUESTION_ENTITY, USERS } from '../constants/EntityName';

// It is hard-coded data and needs to be updated whenever the id of Categories change on backend
export const categoryIds: { [key: string]: string } = {
    fresh: '1',
    kitchen: '2',
};

export const getOutgoingPayload = (
    entityName: string,
    rawPayload: any,
    additionalData: any,
    extraPayloadData?: any,
    incomingFormData?: any,
    nestedEntityName?: string,
    isCreateMode?: boolean,
) => {
    switch (entityName) {
        case USERS: {
            return Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'permissions') {
                    acc['permissions'] = value
                        .map((permission: any) => {
                            const arr = [];
                            if (permission['create']) {
                                arr.push('create');
                            }
                            if (permission['read']) {
                                arr.push('read');
                            }
                            if (permission['update']) {
                                arr.push('update');
                            }
                            if (permission['delete']) {
                                arr.push('delete');
                            }
                            if (arr.length) {
                                return {
                                    subject: permission['name'],
                                    actions: arr,
                                };
                            } else {
                                false;
                            }
                        })
                        .filter((Permission: any) => Permission);
                    return acc;
                }
                return { ...acc, [key]: value };
            }, {});
        }
        case QUESTION_ENTITY: {
            const payload = Object.entries(rawPayload).reduce((acc: any, [key, value]: any) => {
                if (key === 'options') {
                    acc['options'] = value.map((item: any) => ({
                        'text': item?.text,
                        'icon': item?.icon ? item?.icon[0]?.response?.data : '',
                    }));

                    return acc;
                }
                return { ...acc, [key]: value };
            }, {});
            return payload;
        }
    }

    const entityNamesArray = entityName?.split('/');
    const requiredEntityName = entityNamesArray[entityNamesArray.length - 1];

    switch (`/${requiredEntityName}`) {
        default:
            return rawPayload;
    }
};
