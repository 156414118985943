import { errorMsg } from '../constants/messages';

const AdvertisementFormData = [
    {
        title: 'Advertisement',
        name: 'tags',
        label: 'Tags',
        fieldType: {
            name: 'multipleTextInput',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Tag', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'adContent',
        label: 'Ad Content',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Ad Content' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'country',
        label: 'Country',
        fieldType: {
            name: 'select',
            options: [],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Country' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'state',
        label: 'State',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'State' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'city',
        label: 'City',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'City' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'frequency',
        label: 'Frequency',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Frequency' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'isPermanent',
        label: 'Permanent',
        fieldType: {
            name: 'select',
            options: [
                { name: 'Yes', value: true },
                { name: 'No', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Permanent' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'fromDateTime',
        label: 'From Date Time',
        fieldType: {
            name: 'date',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'From Date Time' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'toDateTime',
        label: 'To Date Time',
        fieldType: {
            name: 'date',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'To Date Time' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { AdvertisementFormData };
