import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Spin } from 'antd';

import { VERIFY_EMAIL } from '../../constants/EntityName';
import callApi from '../../Api/config';
import { toastError, toastSuccess } from '../../service/Toast';

import './style.scss';

const SignUp = () => {
    const [loading, setLoading] = useState(true);
    const [verificationText, setVerificationText] = useState('Verification under process...');
    const [verified, setVerified] = useState(false);
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const isExecuted = useRef(false);

    useEffect(() => {
        if (!token) {
            toastError('Invalid or missing token.');
        }
    }, [token]);

    const signUpLinkHit = async () => {
        try {
            const res = await callApi(VERIFY_EMAIL, 'GET', null, { token });
            if (res?.data?.isSuccess) {
                toastSuccess(res?.data?.message);
                setVerified(true);
                setVerificationText(res?.data?.message);
            } else {
                setVerified(false);
                toastError(res?.data?.message);
                setVerificationText(res?.data?.message);
            }
        } catch (error) {
            setVerified(false);
            toastError('An error occurred while verifying your email.');
            setVerificationText('Verification failed.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!isExecuted.current) {
            signUpLinkHit();
            isExecuted.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='sign-up-container'>
            {loading ? (
                <div className='loader'>
                    <Spin size='large' />
                    <p>{verificationText}</p>
                </div>
            ) : (
                <h1 className={verified ? 'verified-text' : 'not-verified-text'}>{verificationText}</h1>
            )}
        </div>
    );
};

export default SignUp;
