import { Form, Select } from 'antd';

import { InputInterface } from '../utils/forms/FormInterface';

import './style.scss';

const { Option } = Select;

interface OptionalSelectInputProps {
    formInput: InputInterface;
    index: number;
}

const OptionalSelectInput = (props: OptionalSelectInputProps) => {
    const { formInput, index } = props;
    const {
        fieldType: { dependency, isVisible },
    } = formInput;

    return (
        <Form.Item
            shouldUpdate={(prevValues, currentValues) => prevValues[dependency] !== currentValues[dependency]}
            noStyle>
            {({ getFieldValue }) =>
                getFieldValue(dependency) === isVisible[dependency] ? (
                    <Form.Item
                        key={index}
                        label={formInput.label}
                        name={formInput.name}
                        className='text'
                        wrapperCol={{ span: 8, offset: 4 }}
                        labelCol={{ span: 4 }}
                        labelAlign='left'
                        rules={[
                            {
                                required: formInput.required,
                                message: formInput.errorMsg,
                            },
                        ]}>
                        <Select
                            placeholder={formInput.placeholder}
                            disabled={formInput.readonly}
                            allowClear
                            showSearch
                            optionFilterProp='children'
                            style={{ width: '100%' }}>
                            {formInput.fieldType.options?.map((obj: any, index: number) => (
                                <Option key={index} value={obj.value}>
                                    {obj.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                ) : null
            }
        </Form.Item>
    );
};

export default OptionalSelectInput;
